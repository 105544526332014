import React from 'react';
import { Link } from 'react-router';

class development extends React.Component {
    render () {
        return (
            <div>
                this is development
            </div>
        )
    }
}
export default development;